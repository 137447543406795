import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import Memberlogin from './Memberlogin';


function Header() {
    function clickHandler() {
		console.l(Memberlogin)
    }
	return (
		<div>
			<button onClick={clickHandler}>Member Login</button>
		</div>
	);
}

export default Header;