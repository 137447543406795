/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:49314589-10d1-4c6e-8993-7e013faefb77",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_i6axn2HOW",
    "aws_user_pools_web_client_id": "1t7cesshvk7jsdgeltc71hnbs3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://4xzhzlgenbgfjbtxey5lpoy6oy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-y27igoxhdfd5rccfyeec53cgay",
    "aws_cloud_logic_custom": [
        {
            "name": "formapi",
            "endpoint": "https://qgtvcz38h0.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "cylm-storage-d2d05gp7nci1me45446-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "cylmcontactformtable-staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
