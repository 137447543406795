import React from 'react';
import { IconSearch} from '@aws-amplify/ui-react';

function Navbar() {
  return (
    <div className="Navbar">
      <div>
        <img alt="CYLM-logo" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/logo.svg" />
      </div>
      <li>
        <a href="/about">ABOUT</a>
      </li>
      <li>
        <a href="/trainings">TRAININGS</a>
      </li>
      <li>
        <a href="/resources">RESOURCES</a>
      </li>
      <li>
        <a href="/declaration">DECLARATION SERIES</a>
      </li>
      <li>
        <a href="/testimonials">TESTIMONIALS</a>
      </li> 
      <li>
        <a href="/contact">CONTACT US</a>
      </li> 
      <div>
        <IconSearch className="Searchicon"></IconSearch>
      </div>
    </div>
  );
}

export default Navbar;