import React from 'react';

function Membernavbar() {
  return (
    <div className="Membernavbar"> 
      <div className="navcontent">  
        <a href="/declaration">Daily Tracker</a>
        <a href="/contact">Schedule</a>
        <a href="/testimonials">My Training Path</a>
        <a href="/resources">Blog Posts</a>
        <a href="/trainings">Partner Profile</a>
        
      </div>
    </div>
  );
}

export default Membernavbar;