import React, { Component } from 'react';
import '@aws-amplify/ui-react/styles.css';
import {Howl} from "howler";

const audioClips = [
	{sound: "https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/TashaPage_Lockhartfeat_BryanPopin_BeautifulSavior.mp3", label: "Featured Lyric Song: Beautiful Jesus Bryan Popin feat. Tasha Page-Lockhart"}
]

class Feature extends Component {

	soundPlay = (src) => {
		const sound = new Howl ({
			src,
			html5: true
		})
		sound.play();
	}
	
	RenderButtonSound = () => {
		return audioClips.map((soundObj, index) => {
			return(
				<button className="soundbutton" key={index} onClick={() => this.soundPlay(soundObj.sound)}>
					{soundObj.label}
				</button>
			)
		})
	}

	render(){
	return (
		<div className="Feature">
			<h2> </h2>
			{this.RenderButtonSound()}
		</div>
    );
}
}
	
export default Feature;	
