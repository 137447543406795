import React from 'react';

function Footer() {
    return (
        <div className="Footer">
            <div className="Footernavbar"> 
                <div className="Footerrow1">
                    <a href="/declaration">Daily Tracker</a>
                    <a href="/contact">Schedule</a>
                    <a href="/testimonials">My Training Path</a>
                    <a href="/resources">Blog Posts</a>
                    <a href="/trainings">Partner Profile</a>
                </div>
                <div className="Footerrow2">
                    <a href="/about">About</a>
                    <a href="/trainings">Trainings</a>
                    <a href="/resources">Resources</a>
                    <a href="/declaration">Declarations Series</a>
                    <a href="/testimonials">Testimonials</a>
                    <a href="/contact">Contact Us</a>
                </div>
                <div className="Footerrow3">
                    <a href="/memberlogin">Member Login</a>
                    <a href="/donate">Donate</a>
                </div>
            </div>
            <div className="Footercopy">
                <li>
                    “Your life transformation has now begun!”
                </li>
                <li>
                    Receive the simple gospel.
                    Liberate the oppressed.
                    Fulfill God’s calling for your life.
                </li>                
                <li className="Copyright">©2022 Changing Your Life Ministries Inc. All rigts reserved.</li>
            </div>
        </div>
       
    );
}

export default Footer