import { API } from 'aws-amplify';
import React from 'react';


async function addContact() {
    const data = {
        body: {
            name: formState.name,
            email: formState.email,
            phone: formState.phone,
            message: formState.message
        }
    };

    console.log(data);
    const apiData = await API.post('formapi', '/contact', data);
    console.log({ apiData });
    alert('Thank you for reaching out. Your message is somewhere in transit.');
}

const formState = { name: '', email: '', phone: '', message: '' };

function updateFormState(key, value) {
  formState[key] = value;
}

function Contactform() {
    return (
        <div className="Form">
            <div className= "Formsubheadings">
                <li>Name</li>
                <li>Email</li>
                <li>Phone</li>
                <li>Message</li>
            </div>
            <div className= "Formfields">
                <div>
                    <input placeholder="Name" onChange={event => updateFormState('Name', event.target.value)} />
                </div>
                <div>
                    <input placeholder="Email" onChange={event => updateFormState('Email', event.target.value)} />
                </div>
                <div>
                    <input placeholder="Phone" onChange={event => updateFormState('Phone', EventSource.target.value)} />
                </div>
                <div className="Messagebox">
                    <input placeholder="Message" onChange={event => updateFormState('Message', event.target.value)} />
                </div>
            </div>

        </div>
    );
}

export {addContact};
export default Contactform;