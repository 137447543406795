import React from 'react';
import './Styles/App.css';
// import DeclarationsCardCollection from './ui-components/DeclarationsCardCollection.jsx';
// import TestimonialsCardCollection from './ui-components/TestimonialsCardCollection.jsx';
import Membernavbar from './othercomponents/Membernavbar';
import Navbar from './othercomponents/Navbar';
import Header from './othercomponents/Header';
import Feature from './othercomponents/Feature';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slides from './othercomponents/Slides';
// import ResourceCardCollection from './ui-components/ResourcesCardCollection.jsx';
import Contactform from './othercomponents/Contactform';
import addContact from './othercomponents/Contactform';
import Footer from './othercomponents/Footer';


function App() {
  return (
    
    <div className="App">
      <div className="Header"> 
        <Header />
      </div>
      <div className="Membernavbar"> 
        <Membernavbar />
      </div>
      <div className="Navbar">
        <Navbar />
        
      </div>
      <div className="Slides"> 
        <Slides />
      </div>
      <div className="Feature">
        <div className="Featuretext">
          <Feature />
        </div>
        <img alt="Featureicon" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/icon_soundresized.png" />
      </div>
      <div className="Aboutsectionheader"> 
        <h1>WHO WE ARE</h1>
      </div>
      <div className="About">
        <div className="Vision">
          <img alt="Vision-logo" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/icon_telescope_alt.png" />
          <p>The Vision of Change Your Life Ministries, Inc., is to:
            teach people about the transforming power of God.
            teach people how to have a real relationship with Jesus Christ,
            teach people how to live and walk by faith, and, to help people reach their full potential as believers in Christ.
          </p>
        </div>
        <div className="Mission">
          <img alt="Mission-logo" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/icon_compass_alt.png"/>
          <p>The Mission of Changing Your Life Ministries, Inc. is to teach and preach the Word of God with power, simplicity, and accuracy so that those who hear and believe can be saved and experience healing, deliverance and freedom from any bondage that has taken them captive.  Faith is the key that unlocks the door to everything that God has done for us.</p>
        </div>
        <div className="Statement">
          <img alt="Statement-logo" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/icon_scroll_alt.png"></img>
          <p>We believe:
          the Bible to be the Infallible Word of God,
          that God is concerned about the Totality of Man (spirit, soul and body),
          and that Jesus is the Christ, the Son of the Living God. He died and was buried, rose again with all power and authority, and,
          that as a result of what Jesus did on the cross, we can live in total victory, if we live according to His Word.</p>
        </div>
        <div className="Leadership">
          <img alt="Leadership-logo" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/icon_leader_alt.png"></img>
          <p>Ministers Lawrence and Sondra Moody are the Founders of Changing Your Life Ministries, Inc., a teaching outreach ministry committed to teaching and preaching God’s Word with “simplicity,” “accuracy” and “power,” with a firm belief that an encounter with God, through His Word will change your life forever. Lawrence and Sondra have been married for over thirty years, and have one child, Savannah-Grace. Based out of Waldorf, Maryland, they are members of The National Church of God.</p>
        </div>
      </div>
      <span><hr/></span>
      <div className="Trainingsectionheader"> 
        <h1>FEATURED TRAINING: MIND RENEWAL</h1>
      </div>
      <div className="Training"> 
        <p>
          When dealing with the mind, the Body of Christ has often fallen into one of two ditches. One ditch is to make everything about Christianity into an intellectual or academic endeavor, the other ditch is to over spiritualize everything.

          The church has almost completely ignored the plain scriptural commands to dealing with our mind. The Scriptures are abundantly clear...

          The ONLY way to life transformation is through the RENEWING of our MIND!  Yet, a vast majority of Christians are led to believe that their lives can be changed by prayer, by fasting, by receiving a "blessing" from a "man of God."

          “Your Mind Is Renewed To The Degree That It Dictates Your Daily Actions!” (Curry Blake)

          In this TRAINING seminar, assembled by Curry Blake, you will receive revelation about not only the command to renew your mind but also TRAIN you how to renew your mind in every area of your life.
        </p>
      </div>
      <div className="Donate">
        <p>Have you been impacted by this message, and are you looking to support this ministry in bringing transformation to the lives of others?  
        </p>
        <button className="Donate-button">
          <a style={{display: "table-cell"}} href = "https://www.paypal.com/us/home" target = "_blank" 
          rel = "noopener noreferrer">Donate</a>
        </button>
      </div>
      <span><hr/></span>
      <div className="Resourcessectionheader">
        <h1>FEATURED RESOURCES</h1>
      </div>
      <div className="Resources">
        <div className="Resource1">
          <div>
            <img alt="Resource1Image" src="https://g.christianbook.com/dg/product/cbd/f400/1229.jpg"></img>
          </div>
          <div>
            <h1>THE NEW TESTAMENT: AN EXPANDED TRANSLATION</h1>
          </div>
          <div>
            <p>Kenneth S. Wuest</p>
          </div>
          <div>
            <p>Drama and action, abrupt exclamation, severe rebuke, stark contrasts--the Greek New Testament is full of these, but standard English versions don't come across this way. This carefully researched translation pays close attention to the original style and force, sentence structure and verb tense meanings, and succeeds in bringing out the richness and clarity of the Greek. 
            An essential work for scholars and refreshing reading for everyone else...this translation uses as many English words as are necessary...<a href="https://www.christianbook.com/the-new-testament-an-expanded-translation/9780802808820/pd/1229?en=google&event=SHOP&kw=bibles-20-40%7C1229&p=1179710&utm_source=google&dv=c&gclid=Cj0KCQjwjN-SBhCkARIsACsrBz4VqhH9JpcF7ye3Ieiau8fJkM2lfELFkB0oTjT1J0eqMpf59Kvz3YsaAhfiEALw_wcB">Explore</a>
            </p>
          </div>
        </div>
        <div className="Resource2">
          <div>
            <img alt="Resource2Image" src="https://cdn.shopify.com/s/files/1/1183/7642/products/mind_renewal-finished_9174a7e6-9bdc-4f1c-a028-00c1b210595c_1024x1024.jpg?v=1458484284"></img>
          </div>
          <div>
            <h1>MIND RENEWAL MANUAL</h1>
          </div>
          <div>
            <p>Curry R. Blake</p>
          </div>
          <div>
            <p>“Your Mind Is Renewed To The Degree That It Dictates Your Daily Actions!” When dealing with the mind, the Body of Christ has often fallen into one of two ditches. One ditch is to make everything about Christianity into an intellectual or academic endeavor, the other ditch is to over spiritualize everything. The church has almost completely ignored the plain scriptural commands to dealing with our mind. 
              The Scriptures are abundantly clear... The ONLY way to life transformation is through the RENEWING of our MIND!...<a href="https://john-g-lake-ministries.myshopify.com/collections/renewing-your-mind/products/mind-renewal-manual-pdf">Explore</a>
            </p>
          </div>
        </div>
      </div>
      <div className="Resourcesnote">
        <p>“I am an avid reader. These are books that have helped increase my faith and opened up better understanding of the scriptures in certain areas.  Always reference what you read by aligning it with the Word of God. Enjoy!”
        </p>
        <p>
          ~Sondra Moody.
        </p>
      </div>  
      <span><hr/></span>
      <div className="Declarationsectionheader">   
        <div>
          <h1>21-DAY DAILY DECLARATION SERIES</h1>
        </div>
      </div>
      <div className="Declarationcards"> 
        <div>
          <h2>JESUS SAID TO HIM, "IF YOU CAN BELIEVE, ALL THINGS ARE POSSIBLE TO HIM WHO BELIEVES."</h2>
        </div>
        <div>
          <p>I BELIEVE THE WORDS OF THE LORD. ALL THINGS ARE POSSIBLE TO ME IN CHRIST BECAUSE I BELIEVE.</p>
        </div>
        <div>
          <p>MARK 9:23</p>
        </div>
      </div>
      <span><hr/></span>
      <div className="Testimonialsectionheader"> 
        <h1>TESTIMONIALS</h1>
      </div>
      <div className="Testimonialcards"> 
        <div>
          <img alt="Testimonialimage" src="https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/Theophilusphoto.jpg"></img>
        </div>
        <div className="Testimonialtext">
            <li>
              <p>
                “I have known Lawrence and Sondra personally. They definitely have a love for the word of God, setting people free from captivity. They show you that you can walk like Jesus, too. They show simplicity in how they speak and even in their lifestyle, have a heart to serve and really care for those who have been hurt and overlooked, and boy can they cook for the town!”
              </p>
            </li>
            <li>
              <h1>
                Theophilus
              </h1>
            </li>
            <li>
              <h1>
                Baltimore, MD
              </h1>
            </li>
        </div>
      </div>
      <span><hr/></span>
      <div className="Contactformsectionheader"> 
        <h1>CONTACT US</h1>
      </div>
      <div className="Contactform"> 
        <div>
          <h1>How may we help you change your life for good?</h1>
          <h2>MINISRY CONTACT FORM  /  SUBMIT A REQUEST</h2>
        </div>
        <div>
          <p>
            Hi, we have seen so many lives transformed from virtually all walks of life though the saving power of the simple gospel of the kingdom of God through Jesus Christ. Sick are healed, people are delivered, prisoners are set free, lives are changed forever, and yours can, too. Ready to fulfill God’s purpose? Ready for lasting transformational change? 
            Contact us to learn how we can help you along in your journey.
          </p>
        </div>
        <div className="Contactformform">
          <Contactform />
        </div>
        <div className="Formsubmitbutton">
          <button onClick={addContact}>Submit</button>
        </div>
      </div>
      <div className="Footer"> 
       <Footer/>
      </div>

    </div>
  );
}

export default App;