import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


function Memberlogin() {
    return (
      <div className="Memberlogin"><p>Content In Progress. Please check back shortly hereafter.</p>
</div>
    );
  }

  export default withAuthenticator(Memberlogin);