import React, {Component} from 'react';
import './Slides.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const photos = [
	{
		name: 'Photo 1',
		url: "https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/cover1cylm.png"
    },
	{
		name: 'Photo 2',
		url: "https://cylm-storage-d2d05gp7nci1me45446-staging.s3.amazonaws.com/public/cover2cylm.png"
    }
]

class Slides extends Component{
	render(){
		const settings = {
			dots: true,
			fade: true,
			infinite: true,
			speed: 500,
			slidesToShow: 2,
			arrows: true,
			slidesToScroll: 1,
			className: "slides"
		}
        return (
            <div className="Slides" style={{padding: 24}}>
                <Slider {...settings}>
                {photos.map((photo) => {
                    return(
                        <div>
                            <img alt="Slides Photos" width="100%" src={photo.url}/>
                        </div>
                    )
                })}
			    </Slider>
		    </div>
	)
}
}

export default Slides;